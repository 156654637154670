import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEventContext } from '../../../../../contexts/EventProvider'
import { useEventHandlersV2 } from '../../../../../hooks/useEventHandlers'
import usePublishedMedia from '../../../../../hooks/usePublishedMedia'
import Card from '../../../../atoms/Card'
import { chakra, Image, Flex, Button, Text, HStack, LightMode } from '@chakra-ui/react'
import useImageGenerator from '../../../../../hooks/useImageGenerator'
import { ArrowForwardRounded } from '../../../../atoms/Icon'
import redirectToNextIfWindow from '../../../../../utils/redirectToNextIfWindow'

// import useUserLocation from '../../../../../hooks/useUserLocation'

export const USB = () => {
	const navigate = useNavigate()
	const { event, isOwner } = useEventContext()
	const handlers = useEventHandlersV2(event.uuid)
	const publishedMedia = usePublishedMedia()
	const { pathname, search } = useLocation()
	// const userLocation = useUserLocation()
	/**
	 * Build target for redirection based on user role
	 * */
	let redirectTarget = `${handlers.public_keepsake_usb}${search}`
	if (pathname.includes('/event') && isOwner) {
		redirectTarget = `${handlers.keepsake_usb}${search}`
	}
	const handleRedirect = () => redirectToNextIfWindow(redirectTarget)

	const img = useImageGenerator({
		path: '/assets/images/keepsakes/vidday-usb-1',
		ext: 'png',
	})
	const [image, setImage] = useState(img.src)

	useEffect(() => {
		if (publishedMedia && !Array.isArray(publishedMedia)) {
			if (publishedMedia.dvdFullImageUrl) {
				setImage(publishedMedia.usbFullImageUrl)
			}
		}
	}, [publishedMedia])

	return (
		<Card
			onClick={handleRedirect}
			sx={{
				overflow: 'visible',
				borderRadius: 'xl',
				_hover: {
					cursor: 'pointer',
				},
			}}>
			<Image src={image} width="full" height="auto" minHeight={'185px'} borderTopRadius="xl" />

			<Flex justify="space-between" alignItems="center" w="full" px="1rem" h="80px">
				<Flex direction="column">
					<Text fontWeight="bold" variant="title">
						USB Keepsake
					</Text>
					<HStack>
						<Text color="var(--vidday-colors-link)" fontSize="1rem">
							$24.
							<chakra.small top="-4px" position="relative" fontSize="x-small">
								99
							</chakra.small>
						</Text>
						<Text color="#6C7A88" fontSize="1rem" textDecoration="line-through">
							$40
						</Text>
						{/* {userLocation?.country === 'US' && ( 
							<Text mb="1rem" fontWeight="bold"><small>Free Shipping <chakra.span transform="scaleX(-1)" display="inline-block">🚚</chakra.span></small></Text>
						)} */}
					</HStack>
				</Flex>
				<Flex>
					<LightMode>
						<Button aria-label="USB Keepsake" size="md" variant="iconCta" onClick={handleRedirect}>
							<ArrowForwardRounded />
						</Button>
					</LightMode>
				</Flex>
			</Flex>
		</Card>
	)
}

export default USB
