import React from 'react'
import KeepsakePostcard from '../Postcard'
import KeepsakeUSB from '../USB'
import KeepsakeDVD from '../DVD'
import KeepsakeVideoBook from '../VideoBook'
import { HStack, SimpleGrid, Stack } from '@chakra-ui/react'

const KeepsakeCards = ({ displayAsSlider }) => {
	if (!displayAsSlider) {
		return (
			<Stack
				direction={['column', 'row']}
				my="2rem"
				spacing="1rem"
				alignItems={['center']}
				alignContent="center"
				w="full"
				justifyContent={['flex-start', 'center']}>
				<KeepsakePostcard />
				<KeepsakeDVD />
				<KeepsakeUSB />
				<KeepsakeVideoBook />
			</Stack>
		)
	}

	return (
		<SimpleGrid
			columns={[1, 4]}
			my="2rem"
			spacing="1rem"
			alignContent="center"
			justifyContent={['flex-start', 'center']}
			overflowX="auto"
			mx="-1rem"
			px=".5rem"
			pb=".5rem"
			sx={{
				'scrollbarWidth': 'none',
				' msOverflowStyle': 'none',
				'&::-webkit-scrollbar': {
					display: 'none',
				},
			}}>
			<KeepsakePostcard />
			<KeepsakeDVD />
			<KeepsakeUSB />
			<KeepsakeVideoBook />
		</SimpleGrid>
	)
}

KeepsakeCards.defaultProps = {
	displayAsSlider: true,
}

export default KeepsakeCards
